import Web3 from 'web3'
import store from '@/store'
import config from '@/config/app.config.js' // 引入配置文件
import rewardAbi from '@/static/abi/reward.json' // 单币质押合约ABI

let web3
try {
  web3 = new Web3(window.ethereum)
  // web3 = new Web3(new Web3.providers.HttpProvider('https://data-seed-prebsc-1-s1.binance.org:8545'))
} catch {
  web3 = new Web3(
    new Web3.providers.HttpProvider('https://bsc-dataseed1.ninicoin.io')
  )
}
const rewardAddress = config.mebMusdAddress
const currentAccount = store.state.myAcount // 当前钱包账户
const contractStake = new web3.eth.Contract(rewardAbi, rewardAddress)

// 统一错误返回
const failResult = (message) => ({ success: false, message })
// 统一成功返回
const successResult = (result) => ({ success: true, result })

/**
 * pending 获取收益数据
 */
const pending = async () => {
  try {
    const resp = await contractStake.methods
      .pending1(currentAccount)
      .call({ from: currentAccount })
    return successResult(resp)
  } catch (error) {
    return failResult(error)
  }
}

/**
 * rewardTokenList 获取所有可奖励代币合约的地址
 */
const rewardTokenList = async () => {
  try {
    const resp = await contractStake.methods
      .rewardTokenList()
      .call({ from: currentAccount })
    return successResult(resp)
  } catch (error) {
    return failResult(error)
  }
}

/**
 * withdrawables 获取所有可奖励代币合约的地址
 * @address 代币地址
 */
const withdrawables = async (tokenAddress) => {
  try {
    const resp = await contractStake.methods
      .withdrawables(currentAccount, tokenAddress)
      .call({ from: currentAccount })
    return successResult(resp)
  } catch (error) {
    return failResult(error)
  }
}

/**
 * harvest 领取奖励
 * @address 代币地址
 */
const harvest = async (tokenAddress) => {
  try {
    const resp = await contractStake.methods
      .harvest(tokenAddress.toString())
      .send({ from: currentAccount })
    return successResult(resp)
  } catch (error) {
    return failResult(error)
  }
}
/**
 * pendingMeb 获取收益数据
 * @pid 池子id
 * @address 地址
 * @return 收益数据
 */
const userAllDeposits = async () => {
  // const currentAccount = '0xf1eEc483aD448794689D173382dB888521FB01eB'
  try {
    const resp = await contractStake.methods
      .userAllDeposits(currentAccount)
      .call({ from: currentAccount })
    return successResult(resp)
  } catch (error) {
    return failResult(error)
  }
}

/**
 * approveContract  赎回
 * @param index 下标
 */

const withdraw = async (index) => {
  try {
    const resp = await contractStake.methods
      .withdraw(index)
      .send({
        from: currentAccount
      })
    return successResult(resp)
  } catch (error) {
    console.log(error)
    return failResult(error)
  }
}

/**
 * 获取token的pair
 * @address tokenA
 * @address tokenB
 * @return pair
 */
const getLpAddress = async () => {
  store.state.isLoading = true
  setTimeout(() => {
    store.state.isLoading = false
  }, 500)
  try {
    const resp = await contractStake.methods
      .lp()
      .call({})
    return successResult(resp)
  } catch (error) {
    return failResult(error)
  }
}

/**
 * approveContract 授权dex使用币
 * @contractObj 合约对象
 * @param inputValue 输入的数量
 */
const approveContract = async (contractObj, inputValue) => {
  const stringValue = web3.utils.toWei(inputValue.toString(), 'ether')
  const accounts = await web3.eth.getAccounts()
  const myContract = await new web3.eth.Contract(contractObj.abi, contractObj.address)
  console.log(myContract)
  try {
    const resp = await myContract.methods.approve(rewardAddress.toString().toLowerCase(), stringValue).send({
      from: accounts[0]
    })
    return successResult(resp)
  } catch (error) {
    console.log(error)
    return failResult(error)
  }
}

/**
 * pendingMeb 获取收益数据
 * @uint256 lockSecond 质押时长（s）参数
 * @uint256 amount 质押数量
 */
const deposit = async (lockSecond, amount) => {
  amount = web3.utils.toWei(amount.toString(), 'ether')
  try {
    const resp = await contractStake.methods
      .deposit(lockSecond, amount)
      .send({ from: currentAccount || store.state.myAcount })
    return successResult(resp)
  } catch (error) {
    return failResult(error)
  }
}

/**
 *   allowance, 授权dex使用币的额度
 * @contractObj 合约对象
 * @return 额度
 */
const allowance = async (contractObj) => {
  const accounts = await web3.eth.getAccounts()
  const myContract = await new web3.eth.Contract(contractObj.abi, contractObj.address)
  try {
    var resp = await myContract.methods.allowance(accounts[0], rewardAddress).call({
      from: accounts[0]
    })
    resp = web3.utils.fromWei(resp, 'ether')
    return successResult(resp)
  } catch (error) {
    console.log(error)
    return failResult(error)
  }
}

export default {
  pending,
  rewardTokenList,
  withdrawables,
  harvest,
  getLpAddress,
  approveContract,
  deposit,
  userAllDeposits,
  withdraw,
  allowance
}
